import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopBar from "../components/TopBar";
import Main from "../components/Main";

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  margin: "0 0",
  height: "100%",
  [theme.breakpoints.down("xs")]: {
    maxWidth: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    background:
      "no-repeat url(./images/pc_bg.png) bottom center / 100% auto fixed",
  },
}));

export default function Home() {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Wrapper>
      <TopBar />
      <Main />
      <Typography
        style={
          isSmallScreen
            ? { marginTop: "40px", textAlign: "center" }
            : { marginTop: "60px", textAlign: "center" }
        }
      ></Typography>
    </Wrapper>
  );
}
