import { Button, Typography } from "@mui/material";
import { Box, textAlign } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import QRCode from "qrcode.react";
import Phone1 from "../images/phone1.png";

export default function Main() {
  const goToAddress = (address) => {
    window.open(address, "_blank");
  };
  const isSmallScreen = useMediaQuery("(max-width: 696px)");
  const isLargeScreen = useMediaQuery("(max-width: 1600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1366px)");
  return (
    <Box
      sx={{
        maxwidth: isSmallScreen ? "100%" : "960px",
        mx: { xs: "20px", sm: "160px" },
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isSmallScreen ? "column" : "row",
      }}
    >
      <Box>
        {isSmallScreen ? (
          <></>
        ) : (
          <Box
            sx={{
              height: { xs: "24px" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              mt: { md: "120px", xl: "200px" },
            }}
          >
          </Box>
        )}
        <Box
          sx={{
            mt: { xs: "10px", sm: "32px" },
            mr: { xl: "140px", xs: "0" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            sx={{ whiteSpace: { sm: "nowrap" } }}
            variant={
              isSmallScreen
                ? "h9"
                : isMediumScreen
                ? "h6"
                : isLargeScreen
                ? "h7"
                : "h8"
            }
          >
            Bello & Crypto wallet.
          </Typography>
        </Box>
        <Box
        >
          <Typography>
          <font size="3" color="#666666">
          Bello is a simple, fast and secure messaging application, powered by API Telegram with wallet and Blockchain.
            Due to using Telegram API, we can give you the best experience possible in messaging.
            You can also use the wallet  to trade crypto tokens.
          </font>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: { xs: "2rem", md: "1rem", xl: "2rem" },
            textAlign: { xs: "center", sm: "left" },
            paddingTop:{ xs: "2rem"},
          }}
        >
          <a
          href="https://play.google.com/store/apps/details?id=com.bello"
            sx={{
              height: { xs: "2.4rem", md: "2.2rem", xl: "3.5rem" },
              fontSize: { xs: "0.9rem", md: "1rem", xl: "1.4rem" },
              lineHeight: 1,
            }}
          >
            <img
            style={{
              marginRight: isSmallScreen ? "0px" : "40px",
              display: "inline-block",
            }}
          alt="Google play store"
          src="https://alphawallet.com/wp-content/themes/alphawallet/img/google-play.svg"
        /></a>
          <a
          href="https://dz2ui7imhl3ri.cloudfront.net/apk/latest/bello-release.apk"
            sx={{
              height: { xs: "2.4rem", md: "2.2rem", xl: "3.5rem" },
              fontSize: { xs: "0.9rem", md: "1rem", xl: "1.4rem" },
              lineHeight: 1,
            }}
          >
            <img
          alt="Apk Store"
          src="https://alphawallet.com/wp-content/themes/alphawallet/img/apk.svg"
        /></a>
        </Box>
        <Box
          sx={{
            marginLeft: isSmallScreen ? "0px" : "170px",
            mt: { xs: "48px", md: "1.6rem", xl: "48px" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >          
          <QRCode
            bgColor="#000"
            fgColor="#fff"
            level="L"
            style={{ border: "4px white solid", borderRadius: "2px" }}
            value="https://dz2ui7imhl3ri.cloudfront.net/apk/latest/bello-release.apk"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: isSmallScreen ? "column" : "row",
          mt: isSmallScreen ? "60px" : "160px",
        }}
      >
        <img
          style={{
            width: isSmallScreen ? "400px" : "30.8vw",
            height: isSmallScreen ? "340px" : "40vw",
            marginRight: isSmallScreen ? "0px" : "40px",
            display: "inline-block",
          }}
          alt=""
          src={Phone1}
        />
      </Box>
    </Box>
  );
}
