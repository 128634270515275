import { AppBar, Toolbar, Box, Button, Link, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../images/logo.png";

function TopBar({ theme }) {
  const isSmallScreen = useMediaQuery("(max-width: 696px)");
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: { sm: "10px", xs: "4px" },
        position: isSmallScreen ? "relative" : "fixed",
        zIndex: "1",
        top: { xs: 1, sm: 1 },
        left: 0,
        right: 0,
      }}
    >
      <AppBar
        position="static"
        display="flex"
        sx={{
          boxShadow: "none",
          maxWidth: "1920px",
          backgroundColor: "transparent",
          mx: "auto",
        }}
      >
        <Toolbar
          sx={{ justifyContent: isSmallScreen ? "center" : "space-between" }}
        >
          <Box
            sx={{
              height: { xs: "100px" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {isSmallScreen ? (
              <img
                style={{
                  width: "auto",
                  height: "100%",
                  marginRight: "16px",
                }}
                alt=""
                src={Logo}
              />
            ) : (
              <img
                style={{
                  width: "auto",
                  height: "100%",
                  marginLeft: "120px",
                }}
                alt=""
                src={Logo}
              />
            )}

          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: "center",
              position: { xs: "fixed", md: "relative" },
              bottom: { xs: "0" },
              left: { xs: "0" },
              height: { xs: "52px" },
              width: { xs: "100%" },
            }}
          ></Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default TopBar;
