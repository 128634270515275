import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  mode: "dark",
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#ffg",
    },
    darker: {
      main: "rgba(255, 255, 255, 0.5)",
    },
    text: {
      primary: "#fff",
    },
    action: {
      disabledBackground: "rgba(255, 255, 255, 0.2)",
      disabled: "rgba(255, 255, 255, 0.8)",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    body1: {
      fontSize: "1.6rem",
      fontWeight: 400,
      color: "#191919",
      lineHeight: 1.2,
    },
    body2: {
      fontSize: "1.2rem",
      color: "#191919",
    },
    body3: {
      fontSize: "1rem",
      color: "#191919",
    },
    allVariants: {
      color: "#fff",
    },
    h5: {
      fontSize: 24,
      fontWeight: 500,
    },
    h6: {
      fontSize: "1.6rem",
      color: "#191919",
      fontWeight: 1000,
    },
    h7: {
      fontSize: "2rem",
      fontWeight: 'bold',
      cursor: "pointer",
    },
    h8: {
      fontSize: "2.6rem",
      fontWeight: 'bold',
      lineHeight: 1.1,
      cursor: "pointer",
    },
    h9: {
      fontSize: "2.3rem",
      fontWeight: 'bold',
      lineHeight: 1.1,
      cursor: "pointer",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "8px",
          fontWeight: "bold",
          fontSize: "22px",
          paddingLeft: "20px",
          paddingRight: "20px",
          // minWidth: 229,
          backgroundColor: "#E02020",
          color: "#fff",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#E02220",
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "#fff",
        },
        containedPrimary: {
          color: "#fff",
        },
      },
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
